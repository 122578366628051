<template lang="html">
  <div class="content-box">
    <div class="line-group">
      <div class="group-box" v-if="getMapCount == 1">
        <div class="box-item">
          <p class="title">온라인</p>
          <p class="value">{{mainOnLineCount}} 대</p>
        </div>
        <div class="box-item">
          <p class="title">오프라인</p>
          <p class="value">{{mainOffLineCount}} 대</p>
        </div>
        <div class="box-item">
          <p class="title">신규 탐지</p>
          <p class="value">{{mainDetectCount}} 대</p>
        </div>
        <div class="box-item">
          <p class="title">차단 카메라 수</p>
          <p class="value">{{mainBlockCount}} 대</p>
        </div>
        <div class="box-item">
          <p class="title">미확인 알림</p>
          <p class="value red">{{mainEventCount}}</p>
        </div>
      </div>

      <div class="group-box" v-else>
        <div class="box-item">
          <p class="title">상세위치 수</p>
          <p class="value">{{mapList.length}}</p>
        </div>

        <div class="box-item">
          <p class="title">단말기 수</p>
          <p class="value">{{sumOnLineCount + sumOffLineCount}} 대</p>
        </div>

        <div class="box-item">
          <p class="title">온라인</p>
          <p class="value">{{sumOnLineCount}} 대</p>
        </div>

        <div class="box-item">
          <p class="title">오프라인</p>
          <p class="value">{{sumOffLineCount}} 대</p>
        </div>
        <div class="box-item">
          <p class="title">신규 탐지</p>
          <p class="value">{{sumDetectCount}} 대</p>
        </div>
        <div class="box-item">
          <p class="title">차단 카메라 수</p>
          <p class="value">{{sumBlockCount}} 대</p>
        </div>
      </div>



      <div class="side-btn">
        <div v-if="getMapCount != 1">
          <div class="tab-type">
            <div class="radio-tab">
              <input class="radio-input" type="radio" id="radio2-1" name="radio02-1" @input="chgViewGrid(2)" :checked="getMapCount === 2 ? true : false">
              <label class="radio-label" for="radio2-1">2열</label>
            </div>
            <div class="radio-tab">
              <input class="radio-input" type="radio" id="radio2-2" name="radio02-1" @input="chgViewGrid(4)" :checked="getMapCount === 4 ? true : false">
              <label class="radio-label" for="radio2-2">4열</label>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="title-area">
            <h2 class="title-a" v-if="getCompanyGuid == '0'" style="overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:150px;">[ {{companyName}} ]</h2>
            <h2 class="title-a" style="overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:150px;">{{siteName}}</h2>
            <p class="txt-alarm" style="overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:150px;">{{mapName}}</p>
          </div>
        </div>


        <button type="button" class="btn-a" @click="chgViewType()">
          <i class="icon-img ic-map-list-all"></i>
          {{getMapCount === 1 ? '전체 맵 목록' : '홈화면' }}( {{mapList.length}} )
        </button>
      </div>
    </div>

    <div class="w-full mt-5" v-if="isMapLoading">
      <div v-if="getMapCount == 1">
        
        <MapMainBox :fileGuid="fileGuid" :mapGuid="mapGuid" :siteGuid="siteGuid" :companyGuid="companyGuid" :checkFlag="checkFlag"></MapMainBox>
        
      </div>

      <div v-else>
        <div class="grid gap-5 map-group" :class="`grid-cols-${getMapCount}`">
          <div class="map-item" v-for="(mItem, idx) in mapList" :key="idx">
            <div class="title-area">
              <h2 class="title-a" style="overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;" :style="getMapCount == 2 ? 'max-width:220px;' : 'max-width:110px;'">{{mItem.siteName}}</h2>
              <p class="txt-alarm" style="overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;" :style="getMapCount == 2 ? 'max-width:220px;' : 'max-width:110px;'">{{mItem.mapName}}</p>
              <p class="txt-alarm" style="max-width:100px;">미확인 알림</p>
              <span class="alarm-count">{{mItem.eventCount}}</span>
            </div>

            <div class="map-area cursor-pointer" @click="viewMainMap(idx)">
              <MapSimpleBox :fileGuid="mItem.fileGuid" :detectCount="mItem.detectCount"></MapSimpleBox>
            </div>

            <div class="group-box">
              <div class="box-item-s green">
                <p class="title">단말기 수</p>
                <p class="value">{{mItem.deviceCount}}</p>
              </div>
              <div class="box-item-s blue">
                <p class="title">탐지 카메라 수</p>
                <p class="value">{{mItem.detectCount}}</p>
              </div>
              <div class="box-item-s red">
                <p class="title">차단 카메라 수</p>
                <p class="value">{{mItem.blockCount}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import MapMainBox from "@/components/elements/MapMainBox"
import MapSimpleBox from "@/components/elements/MapSimpleBox"

export default {
  async created () {
    await this.initDevice();
    this.checkFlag = new Date().getTime();

    this.preDivisionCount = this.getPreDivisionCount

    // this.deviceLoop = setInterval(() => {
    //   this.initDevice();
    // }, 60000)
  },
  destroyed () {
    if(this.deviceLoop != null) clearInterval(this.deviceLoop)
  },
  components: {
    MapMainBox,
    MapSimpleBox
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCurrentUser","getCompanyGuid","getEventTimestamp","getMapCount","getPreDivisionCount"]),
  },
  data () {
    return {
      fileGuid: '',
      mapGuid: '',
      isMapLoading: false,
      mainMap: '',
      mapList: [],
      deviceList: [],

      companyName: '',
      siteName: '',
      mapName: '',

      mainOnLineCount: 0,
      mainOffLineCount: 0,
      mainDetectCount: 0,
      mainBlockCount: 0,
      mainEventCount: 0,

      sumOnLineCount: 0,
      sumOffLineCount: 0,
      sumDetectCount: 0,
      sumBlockCount: 0,
      sumEventCount: 0,

      deviceLoop: null,
      mapViewIdx: 0,
      checkFlag: 0,

      preDivisionCount: 2,

      
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgMapCount","chgPreDivisionCount"]),
    
    async initDevice () {
      await this.getHomeMapList()
      await this.getHomeDeviceList()

      this.sumDetectCount = 0;
      this.sumBlockCount = 0;
      this.sumEventCount = 0;

      for(let i=0; i<this.mapList.length; i++) {
        let deviceList = this.deviceList.filter(x => x.mapGuid === this.mapList[i].mapGuid)
        
        let detectCount = deviceList.map(item => item.detectCount).reduce((prev, curr) => prev + curr, 0);
        let blockCount = deviceList.map(item => item.blockCount).reduce((prev, curr) => prev + curr, 0);
        let eventCount = deviceList.map(item => item.eventCount).reduce((prev, curr) => prev + curr, 0);
        
        this.sumDetectCount += detectCount
        this.sumBlockCount += blockCount
        this.sumEventCount += eventCount

        this.mapList[i].deviceList = deviceList
        
        this.mapList[i].detectCount = detectCount
        this.mapList[i].blockCount = blockCount
        this.mapList[i].eventCount = eventCount
      }

      const allDeviceCount = this.mapList.map(item => item.deviceCount).reduce((prev, curr) => prev + curr, 0);
      this.sumOffLineCount = this.mapList.map(item => item.offlineCount).reduce((prev, curr) => prev + curr, 0);
      this.sumOnLineCount = allDeviceCount - this.sumOffLineCount


      let mapInfo = this.mapList.find(x => x.mapGuid == this.mapGuid)
      this.mainDetectCount = mapInfo.detectCount;
      this.mainBlockCount = mapInfo.blockCount;
      this.mainEventCount = mapInfo.eventCount;
      this.mainOffLineCount = mapInfo.offlineCount;
      this.mainOnLineCount = mapInfo.deviceCount - mapInfo.offlineCount; 


      this.checkFlag = new Date().getTime();
    },
    chgViewType () {
      if(this.getMapCount === 1) this.chgMapCount(this.preDivisionCount)
      else this.chgMapCount(1)
    },
    chgViewGrid(num) {
      this.preDivisionCount = num;
      this.chgPreDivisionCount(num)
      this.chgMapCount(num)
    },
    viewMainMap (idx) {
      this.chgMapCount(1)
      this.mapViewIdx = idx
      
      this.fileGuid = this.mapList[idx].fileGuid
      this.mapGuid = this.mapList[idx].mapGuid
      this.siteGuid = this.mapList[idx].siteGuid
      this.companyGuid = this.mapList[idx].companyGuid

      this.companyName = this.mapList[idx].companyName
      this.siteName = this.mapList[idx].siteName
      this.mapName = this.mapList[idx].mapName


      let mapInfo = this.mapList.find(x => x.mapGuid == this.mapGuid)
      this.mainDetectCount = mapInfo.detectCount;
      this.mainBlockCount = mapInfo.blockCount;
      this.mainEventCount = mapInfo.eventCount;
      this.mainOffLineCount = mapInfo.offlineCount;
      this.mainOnLineCount = mapInfo.deviceCount - mapInfo.offlineCount; 

    },
    async getHomeMapList () {
      let reqObj = {}
      reqObj.target = `/home/maplist/${this.getCompanyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData.length !== 0) {
          this.isMapLoading = true
          this.mapList = result.msg.retData;

          // 처음에는 첫번째 항목의 지도를 가져 옵니다.(첫번째가 Default)
          this.fileGuid = this.mapList[this.mapViewIdx].fileGuid
          this.mapGuid = this.mapList[this.mapViewIdx].mapGuid
          this.siteGuid = this.mapList[this.mapViewIdx].siteGuid
          this.companyGuid = this.mapList[this.mapViewIdx].companyGuid

          this.companyName = this.mapList[this.mapViewIdx].companyName
          this.siteName = this.mapList[this.mapViewIdx].siteName
          this.mapName = this.mapList[this.mapViewIdx].mapName
        }
      }
    },

    async getHomeDeviceList () {
      let reqObj = {}
      reqObj.target = `/home/devicelistall/${this.getCompanyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceList = result.msg.retData;
      }
    },
  },
  watch: {
    'getEventTimestamp': function () {
      console.log("home event detect ", this.getEventTimestamp)
      this.initDevice()
    }
  }
}
</script>
